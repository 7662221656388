import { getNestedValue, prepareBatch } from "@odata/Data.utils";
import { getRouteByDocumentType } from "@odata/EntityTypes";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import { EntityLockEntity, IDocumentEntity, IEntityLockEntity } from "@odata/GeneratedEntityTypes";
import { DocumentTypeCode, LockTypeCode } from "@odata/GeneratedEnums";
import { BatchRequest } from "@odata/OData";
import { IFormatOptions, prepareQuery, queryFromTree } from "@odata/OData.utils";
import { WithOData } from "@odata/withOData";
import { isDocumentWithDraft } from "@pages/documents/Document.utils";
import { isDefined, isNotDefined, uuidv4 } from "@utils/general";
import { getOneFetch } from "@utils/oneFetch";
import i18next from "i18next";
import { xor } from "lodash";
import React from "react";
import { WithTranslation } from "react-i18next";

import { AppContext } from "../../../contexts/appContext/AppContext.types";
import {
    ActionState,
    FieldVisibility,
    GroupStatus,
    RowAction,
    RowType,
    Status,
    TableBatch,
    ToggleState
} from "../../../enums";
import { IModifierKeys, TRecordAny, TRecordType, TSetState, TValue } from "../../../global.types";
import { IFilterQuery, ITableStorageDefaultCustomData, TableStorage } from "../../../model/TableStorage";
import BindingContext, { areBindingContextsDifferent, IEntity } from "../../../odata/BindingContext";
import { ROUTE_BUSINESS_PARTNER } from "../../../routes";
import memoizeOne from "../../../utils/memoizeOne";
import { FormStorage } from "../../../views/formView/FormStorage";
import { AlertAction } from "../../alert/Alert";
import { WithAlert } from "../../alert/withAlert";
import { getTableIntentLink } from "../../drillDown/DrillDown.utils";
import ProgressBar from "../../progressBar/ProgressBar";
import { IRow, IRowAction, ISort, TCellValue, TId } from "../../table";
import { NoData } from "../../table/NoData";
import { IRowProps } from "../../table/Rows";
import { NEW_ROW_ID } from "../../table/Table.types";
import { isRowSelected } from "../../table/TableUtils";
import { IFieldDef } from "../FieldInfo";
import BulkChangeDialog, { TBulkConfirmData } from "../smartBulkChangeDialog/BulkChangeDialog";
import { BulkChangeAction, replaceComplexActionValues } from "../smartBulkChangeDialog/SmartBulkChange.utils";
import { validateDelete } from "../smartFormDeleteButton/SmartDelete.utils";
import {
    TDeleteCheckServiceResponse,
    TDeleteDependentCheckServiceResponse
} from "../smartFormDeleteButton/SmartFormDeleteButton";
import { IFetchDataArgs, ISmartTableCommonProps } from "./SmartTable";
import {
    getAllRows,
    getFormattedValueFromValues,
    getGroupToggleState,
    getRow,
    getRowsArrayFromRows,
    getToggleState,
    iterateOverRows,
    prepareColumns,
    saveGroupRowsState,
    TCustomRowAction,
    updateRow,
    updateRows
} from "./SmartTable.utils";
import { IGroupToggleEvent, ISmartLoadMoreItemsEvent, SmartTableBase } from "./SmartTableBase";
import SmartTableManager from "./SmartTableManager";
import SmartTableStateBase from "./SmartTableStateBase";

export const LOADING_ROW_ID = "loading";

export interface ISmartODataTableProps extends ISmartTableCommonProps {
    /** List of properties from given entity (set) that are supposed to be shown */
    columns: IFieldDef[];
    childColumns?: IFieldDef[];
    childEntity?: string;
    hierarchy?: string;
    additionalProperties?: IFieldDef[];
    bindingContext: BindingContext;
    onRowSelect?: (bindingContext: BindingContext, props: IRowProps, modifiers?: IModifierKeys) => void;
    onRowContextMenuSelection?: (bindingContext: BindingContext, props: IRowProps, modifiers?: IModifierKeys) => void;
    onRemovedRows?: (rows: BindingContext[]) => void;
    onActiveRowActionCountChange?: (activeRowActionCount: number) => void;
    onSortChange?: (props: ISort[]) => void;
    /** Called before render - can be used to alter the rows */
    rowsFactory?: (rows: IRow[]) => IRow[];
    selectedRows?: BindingContext[];
    /** Set of rows that are already active when the table action has been toggled. (e.g. locked rows when lock action is clicked)*/
    initialActiveRows?: BindingContext[];
    // we need to differentiate two cases:
    //  1. initialActiveRows has already some state, which is saved (e.g. locked documents can be unlocked and when user
    //     unselect it, there is a change, which needs to be saved
    //  2. initialActiveRows are only preselected, but they are handled in same way as user would select it manually,
    //     e.g. user selects some rows in Inbox, then uses toolbar action and we want to preselect these rows for him.
    considerInitialActiveRowsAsChanged?: boolean;
    /** Already built filter that is passed to oData call */
    filter?: IFilterQuery;
    lockProperty?: string;
    /** Callback to refresh toolbar - after group is toggled and allGroupStatus recalculated */
    onGroupToggle?: () => void;
    /** If set to true, all items of the entity set are loaded at once at the start.
     * Otherwise only part of the rows are loaded and rest is loaded on demand. */
    loadAll?: boolean;
    /** If this value is changed, the table will reset */
    forceReload?: boolean;
    /**By default, table auto resets when binding context or columns object is changed.
     * preventAutoReset prevents this and reset has to be force either by "forceReload" prop or via SmartTableManager*/
    preventAutoReset?: boolean;
    /** If storage given, table will expose its state and API inside it. Otherwise, local variable is used. */
    storage?: TSmartODataTableStorage;
    formStorage?: FormStorage;
    ref?: React.Ref<any>;
    enableActionForChildren?: boolean;

    // table will keep active rows when filter is changed
    keepActiveRowsOnFilterChange?: boolean;

    /** Use to define action, both custom and one of the default actions.
     * If you set non-custom action and nothing else, everything will be handled by smart table.
     * If you set custom action and nothing else, table will still handle selection and state of the rows.
     * If you set getActionState, smart table will no longer take or of selected rows state.
     * Other options can be used to manage other custom behavior
     * */
    rowAction?: TCustomRowAction;
}

export interface ISmartODataTableState {
    columns?: IFieldInfo[];
    childColumns?: IFieldInfo[];
    // PERFORMANCE OPTIMIZATION
    // Use Record of rows instead of array of rows and keep order in different variable (rowsOrder).
    // This way, we can grab any row we can instantly just by id, instead of having to iterate over the array every time.
    rows?: Record<string, IRow>;
    // first level of rows in order
    rowsOrder?: string[];
    sort?: ISort[];
    rowCount?: number;
    draftRowsCount?: number;
    /** Set of rows that are already active when the table action has been toggled. (e.g. locked rows when lock action is clicked)*/
    initialActiveRows?: Set<string>;
    /** Set of rows that are have active action*/
    activeRows?: Set<string>;
    /** Set of rows that their action state differs from its original state (active/inactive)*/
    changedRows?: Set<string>;
    undeletableRows?: Record<string, string>;
    loaded?: boolean;
    /** State of all rows when row action is used */
    toggleState?: ToggleState;
    loadingActionCount?: boolean;

    actionProgressCurrent?: number;
    actionProgressMax?: number;

    affectedRows?: IAffectedRow[];
    allGroupStatus?: GroupStatus;
    /* resolve function for custom action, dialog (e.g. bulkChange data) */
    customResolveFn?: (resolution: boolean | IEntity) => void;
}

export interface IAffectedRow {
    key: string,
    isLocked: boolean,
    bc: BindingContext,
    path: string,
    rows: IRow[]
}

export type TSmartODataTableStorage<C extends ITableStorageDefaultCustomData = ITableStorageDefaultCustomData> = TableStorage<ISmartODataTableAPI, C>;

export interface ISmartODataTableAPI {
    getState: () => ISmartODataTableState;
    setState: TSetState<ISmartODataTableProps | any, ISmartODataTableState>;
    getRowsArray: () => IRow[];
    getSort: () => ISort[];
    reloadTable: (args?: IFetchDataArgs) => Promise<void>;
    reloadRow: (bindingContext: BindingContext) => Promise<void>;
    confirmAction: () => Promise<boolean>;
    getAffectedRows: () => Promise<IAffectedRow[]>;
    cancelAction: () => void;
    toggleAll: (rowAction: RowAction, checked: boolean) => Promise<void>;
    toggleAllGroups: (status: GroupStatus) => void;
    getAllGroupStatus: () => GroupStatus;
    reset: () => void;
    forceUpdate: () => void;
}

export const defaultODataTableState: ISmartODataTableState = {
    columns: [],
    childColumns: [],
    rows: {},
    rowsOrder: [],
    rowCount: null,
    initialActiveRows: null,
    activeRows: new Set(),
    changedRows: new Set(),
    undeletableRows: {},
    sort: null,
    loaded: false,
    toggleState: ToggleState.AllUnchecked,
    actionProgressCurrent: null,
    actionProgressMax: null,
    customResolveFn: null,
    loadingActionCount: false
};

export const defaultODataTableProps: Omit<ISmartODataTableProps, keyof WithOData | keyof WithAlert> = {
    columns: [],
    childColumns: undefined,
    bindingContext: null,
    tableId: null,
    storage: null
};

/** How many items for action have to be selected to show ProgressBar instead of basic BusyIndicator */
const NUM_ITEMS_PROGRESSBAR_THRESHOLD = 3;

/** Base class for SmartTable and SmartHierarchyTable */
abstract class SmartODataTableBase<P extends ISmartODataTableProps & WithOData & WithAlert & WithTranslation, S extends ISmartODataTableState> extends SmartTableStateBase<P, ISmartODataTableState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    rowsBackup: Record<string, IRow>;
    toggleStateBackup: ToggleState;
    fetchDataOneFetch = getOneFetch();
    reloadRowInternalOneFetch = getOneFetch();

    constructor(props: P) {
        super(props);

        this.handleRowActionClick = this.handleRowActionClick.bind(this);
    }

    handleLoadMoreRows: (args: ISmartLoadMoreItemsEvent) => void;

    componentDidMount() {
        this._isMounted = true;

        const tableAPI = {
            getState: this.getTableState,
            setState: this.setState.bind(this),
            getRowsArray: this.getRowsArray.bind(this),
            getSort: this.getSort.bind(this),
            reloadTable: this.fetchData.bind(this),
            reloadRow: this.reloadRow.bind(this),
            getAffectedRows: this.getAffectedRows,
            confirmAction: this.confirmAction,
            cancelAction: this.cancelAction,
            toggleAll: this.toggleAll,
            toggleAllGroups: this.toggleAllGroups,
            getAllGroupStatus: () => {
                return this.state.allGroupStatus;
            },
            reset: this.reset.bind(this),
            forceUpdate: this.forceUpdate.bind(this)
        };

        SmartTableManager.registerTable(this.props.tableId, tableAPI);

        this.init()
            .then(() => {
                if (this.props.rowAction?.actionType && this.shouldHandleRowActionState) {
                    this.initRowAction();
                }
            });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        // cancel async fetch task
        this.fetchDataOneFetch.abort();
        this.reloadRowInternalOneFetch.abort();
        SmartTableManager.unregisterTable(this.props.tableId);
    }

    componentDidUpdate(prevProps: P, prevState: S) {
        const contextChanged = areBindingContextsDifferent(prevProps.bindingContext, this.props.bindingContext);
        const columnsChanged = prevProps.columns !== this.props.columns;
        const forceReload = this.props.forceReload !== prevProps.forceReload;
        const filterChanged = prevProps.filter?.query !== this.props.filter?.query;

        if ((!this.props.preventAutoReset && (contextChanged || columnsChanged)) || forceReload) {
            this.reset();
        } else if ((!this.props.preventAutoReset && filterChanged) || (!prevProps.loadAll && this.props.loadAll && !this.areAllRowsLoaded())) {
            // allGroupStatus is used in createRowProperties to set "open" value of rows
            // => set to unknown so that the last state is not carried over for all the rows
            this.setState({
                allGroupStatus: GroupStatus.Unknown
            }, this.fetchData);
        } else if (this.state.loaded && this.props.isForPrint && this.props.loadAll && this.state.rowCount === this.state.rowsOrder.length) {
            // we need to know that the table is already ready for printing, without loading another data
            this.props.onAfterTableLoad?.();
        }

        if (this.shouldHandleRowActionState) {
            // row action turned on
            if (!prevProps.rowAction?.actionType && this.rowActionType) {
                this.initRowAction();
            }
            // row action turned off
            if (prevProps.rowAction?.actionType && !this.rowActionType) {
                this.cancelRowAction(prevProps.rowAction?.actionType);
            }
        }

        // reset action row selection on filter change
        // todo maybe instead of filterChanged this should happen anytime the rows are reloaded?
        if (filterChanged && !this.props.keepActiveRowsOnFilterChange) {
            this.handleToggleChange(ToggleState.AllUnchecked);
        }
    }

    get rowActionType(): RowAction {
        return this.props.rowAction?.actionType;
    }

    /** By default, table handles row action state (e.g. selected rows).
     * If props.rowAction.getActionState is defined, state will no longer be handled by table.*/
    get shouldHandleRowActionState(): boolean {
        return !this.props.rowAction?.getActionState;
    }

    saveGroupRowsState = (rows: IRow[]): void => {
        if (this.props.hierarchy && this.state.loaded && this.state.rows?.length) {
            saveGroupRowsState(rows, this.props.tableId, this.props.storage);
        }
    };

    _validateDeleteOneFetch = getOneFetch();
    setUndeletableRows = async (rows: IRow[], undeletableRows: Record<string, string>): Promise<void> => {
        // ignore groups and rows that are not loaded yet
        const filteredRows = rows.filter(r => r.type !== RowType.Group && (r.id as BindingContext).getKey && !r.isLoading);
        const errors = await validateDelete({
            bindingContext: this.props.bindingContext,
            ids: filteredRows.map(r => (r.id as BindingContext).getKey()),
            fetchFn: this._validateDeleteOneFetch.fetch
        });

        for (const error of errors) {
            if (error.ErrorCodes?.length > 0) {
                const id = (error as TDeleteCheckServiceResponse).EntityId ?? (error as TDeleteDependentCheckServiceResponse).DependentEntityId;

                undeletableRows[id.toString()] = error.ErrorCodes[0];
            }
        }
    };

    initRowAction = async (): Promise<void> => {
        const state = this.getTableState();
        const initialActiveRows = await this.getInitialActiveRows();
        let toggleState;
        const draftDef = this.props.storage.data.definition.draftDef;

        this.rowsBackup = { ...state.rows };

        if (this.rowActionType === RowAction.Remove
            && this.props.bindingContext.getRootParent().getEntitySet().getName() !== draftDef?.draftEntitySet) {
            this.setState({
                loaded: false,
                activeRows: new Set<string>()
            });

            const rows = getAllRows(this.getRowsArray());
            const undeletableRows: Record<string, string> = {};

            await this.setUndeletableRows(rows, undeletableRows);

            toggleState = this.areAllRowsLoaded() && Object.keys(undeletableRows).length === rows.filter(r => r.type !== RowType.Group).length ? ToggleState.Disabled : ToggleState.AllUnchecked;

            this.setState({
                loaded: true,
                undeletableRows
            }, () => {
                // refresh table toolbar -> if action is canceled, loaded state might be set later than the toolbar is
                // refreshed and then it stuck in loading state without additional refresh
                this.props.onToolbarRefreshNeeded?.();
            });
        } else {
            toggleState = this.getToggleState(this.getRowsArray(), initialActiveRows);
        }
        this.toggleStateBackup = toggleState;

        const hasActiveRowActionCountChanged = this.state.activeRows.size !== initialActiveRows.size;
        const initialChangedRows = new Set<string>(this.props.considerInitialActiveRowsAsChanged ? initialActiveRows : []);
        const hasChangedRowsCountChanged = this.state.changedRows.size !== initialChangedRows.size;

        this.setState({
            toggleState: toggleState,
            initialActiveRows,
            activeRows: initialActiveRows,
            changedRows: initialChangedRows
        }, () => {
            if (hasActiveRowActionCountChanged || hasChangedRowsCountChanged) {
                this.props.onActiveRowActionCountChange?.(initialActiveRows.size);
            }
        });
    };

    cancelRowAction = (actionType: RowAction): void => {
        if (actionType === RowAction.Remove) {
            this._validateDeleteOneFetch.abort();
        }
    };

    /** Automatically add intent navigation for BusinessPartner (or other entities in the future) */
    enhanceColumnsWithIntentNavigation = (columns: IFieldInfo[]) => {
        for (const column of columns) {
            if (!column.bindingContext.isNavigation()) {
                continue;
            }

            const entityType = column.bindingContext.getEntityType();

            if (entityType.getFullName() === "Solitea.Evala.DomainModel.DocumentBusinessPartner" || entityType.getFullName() === "Solitea.Evala.DomainModel.BankTransactionBusinessPartner") {
                const additionalProperties = column.additionalProperties ?? [];

                if (!additionalProperties.find(fieldDef => fieldDef.id === "BusinessPartner")) {
                    // we need id of the BusinessPartner entity not DocumentBusinessPartner
                    // => add BusinessPartner navigation as additionalProperty to load it
                    additionalProperties.push({ id: "BusinessPartner" });

                    column.additionalProperties = additionalProperties;
                }

                if (!column.formatter) {
                    column.formatter = (val: TValue, args: IFormatOptions): TCellValue => {
                        if (!args.entity?.BusinessPartner?.BusinessPartner?.Id) {
                            return args.entity?.BusinessPartner?.Name;
                        }

                        return getTableIntentLink(val as string, {
                            route: `${ROUTE_BUSINESS_PARTNER}/${args.entity.BusinessPartner.BusinessPartner.Id}`,
                            context: args.storage.context,
                            storage: args.storage
                        });
                    };
                }
            } else if (entityType.getFullName() === "Solitea.Evala.DomainModel.Document" || entityType.hasParentWithBaseType("Document")) {
                const additionalProperties = column.additionalProperties ?? [];

                if (!additionalProperties.find(fieldDef => fieldDef.id === "DocumentType")) {
                    // we need document type
                    additionalProperties.push({ id: "DocumentType" });

                    column.additionalProperties = additionalProperties;
                }

                if (!column.formatter) {
                    column.formatter = (val: TValue, args: IFormatOptions): TCellValue => {
                        const document: IDocumentEntity = getNestedValue(args.bindingContext.getNavigationPath(), args.entity);

                        if (!document?.Id || !document?.DocumentType) {
                            return val as TCellValue;
                        }

                        return getTableIntentLink(val as string, {
                            route: `${getRouteByDocumentType(document.DocumentType.Code as DocumentTypeCode)}/${document.Id}`,
                            context: args.storage.context,
                            storage: args.storage
                        });
                    };
                }
            }
        }
    };

    reset = (): void => {
        this.setState({
            ...defaultODataTableState
        }, () => {
            this.init();
        });
    };

    init = async (): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            if (this.props.bindingContext) {
                await this.props.onBeforeFetch?.();
                const columns = await prepareColumns({
                    bindingContext: this.props.bindingContext,
                    context: this.context,
                    columns: this.props.columns.filter(column => ![FieldVisibility.ExportOnly].includes(column.fieldVisibility))
                });

                this.enhanceColumnsWithIntentNavigation(columns);

                let childColumns = [] as IFieldInfo[];
                if (this.props.childEntity) {
                    childColumns = await prepareColumns({
                        bindingContext: this.props.bindingContext.navigate(this.props.childEntity),
                        context: this.context,
                        columns: this.props.childColumns
                    });

                    this.enhanceColumnsWithIntentNavigation(childColumns);
                }

                this.setState({
                    columns,
                    childColumns
                }, () => {
                    this.fetchData()
                        .then(resolve, reject);
                });
            } else {
                this.setState({
                    loaded: true
                });
                resolve();
            }
        });
    };

    async fetchData(args?: IFetchDataArgs): Promise<void> {
        throw new Error("abstract method of SmartODataTableBase");
    }

    /**
     * Refresh values of one row, without reloading whole table or showing busy indicator
     * @param bindingContext id of the row to refresh
     */
    async reloadRow(bindingContext: BindingContext) {
        throw new Error("abstract method of SmartODataTableBase");
    }

    async reloadRowInternal(bindingContext: BindingContext, columns: IFieldInfo[], updateFn: (newRowData: IEntity, oldRow: IRow) => IRow) {
        const query = prepareQuery({
            oData: this.props.oData,
            bindingContext,
            fieldDefs: [...columns, ...(this.props.additionalProperties ?? [])]
        });

        try {
            const res = await query.fetchData(this.reloadRowInternalOneFetch.fetch);
            const row = res.value;

            const rows = updateRow(this.getTableState().rows, bindingContext, updateFn.bind(this, row));

            this.setState({
                rows
            });
        } catch (error) {
            // ignore errors
            // todo should we try to reload the whole table in this case?
        }
    }

    areAllRowsLoaded = (): boolean => {
        return this.state.rowCount === this.state.rowsOrder.length;
    };

    getRowValues = (row: IEntity, columns: IFieldInfo[] = this.getTableState().columns, valuesBindingContext: BindingContext = this.props.bindingContext) => {
        const formattedValueCallbacks: IEntity = {};

        for (const column of columns) {
            formattedValueCallbacks[column.id] = () => {
                return getFormattedValueFromValues({
                    column, values: row, valuesBindingContext, storage: this.props.storage
                });
            };
        }

        return formattedValueCallbacks;
    };

    getSort = (): ISort[] => {
        return this.getTableState().sort || this.props.initialSortBy;
    };

    handleSortChange = async (sort: ISort[]) => {
        this.props.onSortChange?.(sort);

        this.setState({
            sort
        }, () => {
            this.fetchData();
        });
    };

    /** This function returns locked/unlocked state only if ALL rows are loaded from backend
     * and all of them have the same state */
    getToggleState = (rows: IRow[], activeRows: Set<string>, rowCount: number = this.getTableState().rowCount): ToggleState => {
        if (rowCount !== rows.length) {
            return ToggleState.Other;
        }

        return getToggleState(rows,
            (row) => activeRows.has(row.id.toString()),
            (row) => this.isRowWithoutAction(row.id, this.rowActionType, row) || row.isDisabled,
            this.areAllRowsLoaded());
    };

    getAllLockedRows = async (): Promise<Set<string>> => {
        let filter = `${this.props.lockProperty}/any()`;

        if (this.props.filter?.query) {
            filter = `(${this.props.filter.query}) and ${filter}`;
        }

        const result = (await this.props.oData.fromPath(this.props.bindingContext.toString())
            .query()
            .filter(filter)
            .fetchData<IEntity[]>());

        const activeRows = new Set<string>();
        result?.value.forEach(item => {
            const rowId = this.props.bindingContext.addKey(item.Id);
            activeRows.add(rowId.toString());
        });

        return activeRows;
    };

    getInitialActiveRows = async (): Promise<Set<string>> => {
        // retrieve how many locked rows are there if not all of them are already downloaded to FE
        if (this.rowActionType === RowAction.Lock && !this.areAllRowsLoaded()) {
            return await this.getAllLockedRows();
        }

        const activeRows = new Set<string>();

        if (this.rowActionType === RowAction.Lock) {
            iterateOverRows(this.getRowsArray(), (row: IRow) => {
                if (!this.isRowWithoutAction(row.id, RowAction.Lock, row) && row.isLocked) {
                    activeRows.add(row.id.toString());
                }

                return row;
            });
        } else if (this.props.initialActiveRows?.length) {
            this.props.initialActiveRows.forEach(bc => {
                activeRows.add(bc.toString());
            });
        }

        return activeRows;
    };

    /** If true, action is disabled. Should we rename this to better reflect the current state? */
    isRowWithoutAction = (rowId: TId, action: RowAction, row?: IRow): boolean => {
        if (!(rowId instanceof BindingContext)) {
            return true;
        }

        if (action === RowAction.Remove && !!this.state.undeletableRows[rowId.getKey().toString()]) {
            return true;
        }

        if (!row) {
            row = getRow(this.getTableState().rows, rowId);
        }

        const isRowWithoutAction = this.props.rowAction?.isRowWithoutAction?.(rowId, action, row);

        if (isDefined(isRowWithoutAction)) {
            return isRowWithoutAction;
        }

        // this check is so far only for FiscalYear table
        // where only the parent rows should show action buttons
        return this.props.hierarchy && !this.props.enableActionForChildren && this.props.bindingContext.getProperty()?.getName() !== rowId.getProperty()?.getName();
    };

    getRowActionState = (rowId: TId, rowProps: IRowProps): ActionState => {
        // ignore callback for rows in "loading" state
        if ((typeof rowId === "string" && rowId.includes(LOADING_ROW_ID)) || !rowId) {
            return ActionState.None;
        }

        const row = getRow(this.getTableState().rows, rowId);

        if (rowId === NEW_ROW_ID || this.isRowWithoutAction(rowId, this.rowActionType, row)) {
            return ActionState.Disabled;
        }

        if (this.props.rowAction?.getActionState) {
            return this.props.rowAction?.getActionState?.(rowId, rowProps) ?? ActionState.Disabled;
        }

        return this.state.activeRows.has(rowId.toString()) ? ActionState.Active : ActionState.Inactive;
    };

    getRowActionTooltip = (rowId: TId): string => {
        if (!rowId || typeof rowId !== "object") {
            return null;
        }

        const key = (rowId as BindingContext).getKey().toString();

        if (this.rowActionType === RowAction.Remove && !!this.state.undeletableRows[key]) {
            const transKey = `Error:${this.state.undeletableRows[key]}`;


            return i18next.exists(transKey) ? this.props.t(transKey) : null;
        }

        return null;
    };

    getRowAction = (): IRowAction => {
        if (!this.rowActionType) {
            return null;
        }

        const { isSingleSelect, showIconsOnHover, onClick, render, toggleState } = this.props.rowAction ?? {};

        return {
            // All icons are visible when it is multiselect, or single select without selected row :-/
            isSingleSelect,
            showIconsOnHover: showIconsOnHover ?? (isSingleSelect && this.state.activeRows.size > 0),
            actionType: this.rowActionType,
            getActionState: this.getRowActionState,
            onClick: (onClick as (rowId: TId) => void) ?? this.handleRowActionClick,
            toggleState: toggleState ?? this.state.toggleState,
            onToggleChange: this.handleToggleChange,
            tooltip: this.getRowActionTooltip,
            render
        };
    };

    handleRowActionClick(rowId: TId, rowComponentProps: IRowProps, shouldOpen?: boolean): void {
        const isSingleSelect = this.props.rowAction?.isSingleSelect;
        const updateValue = !this.state.activeRows.has(rowId.toString());
        let updatedRow: IRow = null;
        const activeRows = new Set(isSingleSelect ? undefined : this.state.activeRows);

        const updatefn = (row: IRow, value: boolean) => {
            const newRow: IRow = {
                ...row,
                open: shouldOpen && row.rows ? true : row.open
            };

            if (!this.isRowWithoutAction(row.id, this.rowActionType)) {
                activeRows[value ? "add" : "delete"](row.id.toString());
            }

            return newRow;
        };

        let childrenRows: IRow[];
        let updatedRows = updateRow(this.getTableState().rows, rowId, (row) => {
            const newRow = updatefn(row, updateValue);

            updatedRow = newRow;

            // store to propagate downwards
            if (newRow.rows) {
                childrenRows = newRow.rows;
            }

            return newRow;
        });

        if (childrenRows?.length) {
            // propagate downwards
            updatedRows = updateRows({
                rows: updatedRows,
                rowsOrder: childrenRows.map(r => r.id.toString()),
                updateFn: (row) => {
                    return updatefn(row, updateValue);
                }
            });
        }

        // propagate upwards
        // for lock and also delete action, when you unselect row, also its parents must be unselected, otherwise it would be still removed/locked
        if (!updateValue) {
            let parent = updatedRow.customData?.parent;

            while (parent) {
                updatedRows = updateRow(updatedRows, parent.id, (row) => {
                    parent = row.customData?.parent;

                    return updatefn(row, false);
                });
            }
        }

        this.setState({
            rows: updatedRows,
            toggleState: this.getToggleState(this.getRowsArray(updatedRows), activeRows),
            activeRows,
            changedRows: this.getChangedRows(activeRows)
        }, () => {
            this.props.onActiveRowActionCountChange?.(activeRows.size);
        });
    }

    // if there are initial active rows, calculate changedRows, e.g. for lock action, some rows might be changed
    // even if they are not active and vice versa
    getChangedRows = (activeRows: Set<string>): Set<string> => {
        const { initialActiveRows } = this.state;
        if (initialActiveRows && !this.props.considerInitialActiveRowsAsChanged) {
            const changedIds = xor([...initialActiveRows.values()], [...activeRows.values()]);
            return new Set(changedIds);
        }
        return activeRows;
    };

    getAffectedRows = async (): Promise<IAffectedRow[]> => {
        const toggleState = this.getTableState().toggleState;
        const affectsAllRows = toggleState !== ToggleState.Other && !this.areAllRowsLoaded();

        const affectedRows: IAffectedRow[] = [];

        // if ALL rows are mass checked/unchecked
        // we need to load all rows from backend to update them
        if (affectsAllRows) {
            const isLocked = toggleState === ToggleState.AllChecked;
            const keyPropertyName = this.props.bindingContext.getKeyPropertyName();
            const query = this.props.oData.fromPath(this.props.bindingContext.toString())
                .query()
                .select("Id")
                .filter(this.props.filter?.query)
                .top(this.state.rowCount);

            if (this.props.lockProperty) {
                query.expand(this.props.lockProperty, (q) => {
                    q.select("Id");
                });
            }

            const rows: IEntity[] = (await query.fetchData()).value;

            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];

                const key = row[keyPropertyName];

                // backend doesn't allow changing from locked to locked
                if (this.rowActionType !== RowAction.Lock
                    || !!row[this.props.lockProperty] !== isLocked) {
                    const bc = this.props.bindingContext.addKey(key);

                    affectedRows.push({
                        key,
                        isLocked,
                        bc,
                        path: bc.getPath(),
                        rows: []
                    });
                }
            }
        } else {
            const getAffectedChildRows = (rows: IRow[]) => {
                for (let i = 0; i < rows.length; i++) {
                    const row = rows[i];

                    // row can be undefined - not yet loaded row
                    if (!row) {
                        continue;
                    }

                    const isLockAction = this.rowActionType === RowAction.Lock;
                    const isActive = this.state.activeRows.has(row.id.toString());

                    // backend doesn't allow changing from locked to locked
                    if (isLockAction ? !!row.isLocked !== isActive : isActive) {
                        const bc = (row.id as BindingContext);
                        affectedRows.push({
                            key: bc.getKey().toString(),
                            isLocked: isLockAction ? isActive : row.isLocked,
                            bc: bc,
                            path: bc.getPath(true),
                            rows: row.rows
                        });
                    } else if (this.props.childEntity || this.isRowWithoutAction(row, this.rowActionType)) {
                        row.rows?.length && getAffectedChildRows(row.rows);
                    }
                }
            };
            getAffectedChildRows(this.getRowsArray());
        }
        this.setState({ affectedRows });
        return affectedRows;
    };

    confirmAction = async (): Promise<boolean> => {
        const { bindingContext, storage } = this.props;
        const affectedRows = this.state.affectedRows || await this.getAffectedRows();
        // get data for bulk action before confirmation action starts
        let bulkData;
        // if we are changing collections with bulkChange, we need also original data to be able to create
        // update oData request
        const originalBulkData: TRecordAny = {};
        let bulkComplexColumns: string[] = [];
        let bulkActions: TRecordType<BulkChangeAction>;
        if (this.rowActionType === RowAction.MassEdit) {
            // get data for mass edit
            const args: TBulkConfirmData | boolean = await this.getDataForBulkChange(affectedRows);
            if (typeof args === "boolean") {
                // user canceled the bulk change in dialog
                return args;
            }
            bulkData = args.data;
            bulkActions = args.bulkActions;

            bulkComplexColumns = Object.keys(bulkData).filter(key => {
                return isDefined(bulkActions[key]);
            });
            if (bulkComplexColumns.length) {
                const columns = bulkComplexColumns.map(path => ({
                    id: path,
                    ...(storage.data.definition.massEditableDef[path])
                }));

                const batch = storage.oData.batch();
                const entitySetWrapper = batch.fromPath(bindingContext.toString());
                const automaticQuery = queryFromTree({
                    bindingContext,
                    fieldDefs: columns,
                    navigationTree: {
                        properties: bulkComplexColumns,
                        navigation: {}
                    },
                    settings: {},
                    isRoot: true
                });

                batch.beginAtomicityGroup("queryCollections");

                affectedRows.forEach(row => {
                    const q = entitySetWrapper.query(row.key);
                    automaticQuery(q);
                });

                const result = await batch.execute();
                result.forEach((res: any) => {
                    const entity = res.body.value;
                    originalBulkData[entity.Id] = entity;
                });
            }
        }

        this.setState({
            loaded: false,
            affectedRows: null
        });
        const promises: Promise<any>[] = [];
        const okRows: IAffectedRow[] = [];
        let firstErrorMessage = "";
        let errorCount = 0;
        let finished = 0;
        const showActionProgress = affectedRows.length > NUM_ITEMS_PROGRESSBAR_THRESHOLD;
        const entityWrapper = this.props.oData.fromPath(this.props.bindingContext.toString());

        const fnHandleResPromise = async (promise: Promise<any>, index: number): Promise<void> => {
            return new Promise(async (resolve) => {
                try {
                    await promise;
                    okRows.push(affectedRows[index]);
                } catch (error) {
                    if (!firstErrorMessage && error) {
                        firstErrorMessage = error?._validationMessages?.[0]?.message ?? error?._message;
                    }
                    errorCount += 1;
                }

                finished += 1;

                if (showActionProgress) {
                    this.setState(({
                        actionProgressCurrent: finished
                    }));
                }

                resolve();
            });
        };

        if (showActionProgress) {
            this.setState(({
                actionProgressCurrent: 0,
                actionProgressMax: affectedRows.length
            }));
        }

        for (let i = 0; i < affectedRows.length; i++) {
            const row = affectedRows[i];

            if (this.rowActionType === RowAction.Lock) {
                const originalRow = getRow(this.getTableState().rows, row.bc);
                const locks = originalRow.customData.entity[this.props.lockProperty] as IEntityLockEntity[];
                const originalLock = locks.find(lock => lock.Type.Code === LockTypeCode.User);
                const originalIsLocked = !!originalLock;

                if (originalIsLocked === row.isLocked) {
                    continue;
                }

                const locksDelta: Record<string, unknown>[] = [];

                if (row.isLocked) {
                    locksDelta.push({
                        TypeCode: LockTypeCode.User
                    });
                } else {
                    locksDelta.push({
                        "@odata.removed": {
                            reason: "removed"
                        },
                        [EntityLockEntity.Id]: originalLock.Id
                    });
                }

                promises.push(fnHandleResPromise(
                    (entityWrapper.update(row.key, {
                        [`${this.props.lockProperty}@odata.delta`]: locksDelta
                    }) as Promise<any>), i
                ));
            } else if (this.rowActionType === RowAction.Remove) {
                let removePromise: Promise<any>;

                if (this.props.childEntity && row.path === this.props.childEntity) {
                    removePromise = storage.oData.fromPath(row.bc.removeKey().getFullPath()).delete(row.bc.getKey());
                } else {
                    removePromise = (entityWrapper.delete(row.key) as Promise<any>);
                }

                // DEV-10155 send the remove requests one by one
                // most likely will change in the future, but BE needs this for some entities
                // to prevent deadlocks
                await fnHandleResPromise(removePromise, i);
                // promise array is expected to contain some items
                promises.push(Promise.resolve());
            } else if (this.rowActionType === RowAction.MassEdit) {
                // Bulk change
                const { storage, bindingContext } = this.props;
                const batch: BatchRequest = storage.oData.batch();
                batch.beginAtomicityGroup("group1");
                batch.fromPath(bindingContext.toString());
                const result = prepareBatch({
                    entity: replaceComplexActionValues(storage, bulkData as IEntity, originalBulkData[row.key], bulkComplexColumns, bulkActions),
                    originalEntity: originalBulkData[row.key],
                    bindingContext,
                    changesOnly: true,
                    batch
                });

                promises.push(fnHandleResPromise(
                    (entityWrapper.update(row.key, result.values) as Promise<any>), i
                ));
            }
        }

        if (promises.length === 0) {
            this.setState({
                loaded: true,
                activeRows: new Set<string>()
            });

            return false;
        }

        await Promise.allSettled(promises);

        if (showActionProgress) {
            this.setState({
                actionProgressCurrent: null,
                actionProgressMax: null
            });
        }

        const subTitle = this.getConfirmAlertSubtitle(this.rowActionType, okRows.length, errorCount, firstErrorMessage);

        // await load of table so success message is not shown before busy indicator is hidden
        // DEV-19925 - (https://solitea-cz.atlassian.net/browse/DEV-19925)
        await this.fetchData();

        if (subTitle) {
            const isError = errorCount > 0;

            this.props.setAlert({
                title: this.props.storage.t(`Components:Table.${isError ? "UpdateError" : "UpdateOk"}`),
                subTitle: subTitle,
                status: isError ? Status.Error : Status.Success,
                action: isError ? AlertAction.Close : AlertAction.None
            });
        }

        if (this.rowActionType === RowAction.Remove) {
            // we need to pass all removed rows in hierarchy to onRemovedRows, so opened detail is closed on parent removal
            const removedRows: BindingContext[] = okRows.map(row => {
                if (this.props.childEntity && row.path === this.props.childEntity) {
                    return row.bc;
                }
                return this.props.bindingContext.addKey(row.key);
            });
            const getChildRows = (rows: IRow[]) => {
                for (const row of rows) {
                    removedRows.push(row.id as BindingContext);
                    row.rows && getChildRows(row.rows);
                }
            };
            for (const topRow of okRows) {
                topRow.rows && getChildRows(topRow.rows);
            }
            this.props.onRemovedRows?.(removedRows);
            // don't trigger form refresh when item is removed - already handled by url change
            return false;
        }

        return !!(this.props.selectedRows && affectedRows.find(row => isRowSelected(this.props.selectedRows, row.bc)));
    };

    isBulkChangeDialogOpen = (): boolean => {
        return this.rowActionType === RowAction.MassEdit && !!this.state.customResolveFn;
    };

    getDataForBulkChange = (affectedRows: IAffectedRow[]): Promise<any> => {
        return new Promise((resolve) => {
            this.props.storage.setCustomData({ affectedRows });
            this.setState({
                customResolveFn: resolve
            });
        });
    };

    /**
     * User has confirmed bulk change data for save
     * @param args data and additionalFieldData for update
     */
    handleBulkChangeDialogConfirm = (args: TBulkConfirmData): void => {
        this.state.customResolveFn?.(args);
        this.setState({ customResolveFn: null });
    };

    handleBulkChangeDialogCancel = (): void => {
        this.state.customResolveFn?.(false);
        this.cancelAction();
        this.setState({ customResolveFn: null });
    };

    getConfirmAlertSubtitle = (action: RowAction, okCount: number, errorCount: number, errorMsg: string): string => {
        let title = "";

        if (action !== RowAction.Lock && okCount > 0) {
            const message = errorCount === 0 ? "Ok" : "PartialOk";
            title += this.props.storage.t(`Components:Table.${action}${message}`, { count: okCount });
        }

        if (errorCount > 0) {
            if (title) {
                title += " ";
            }

            title += this.props.storage.t(`Components:Table.UpdateErrorSubTitle`, { count: errorCount });
            title += `\n${errorMsg}`;
        }

        return title;
    };

    cancelAction = (): void => {
        const backup: Partial<ISmartODataTableState> = this.rowsBackup ? {
            rows: this.rowsBackup,
            toggleState: this.toggleStateBackup
        } : {};
        this.setState({
            ...backup,
            initialActiveRows: new Set<string>(),
            activeRows: new Set<string>(),
            changedRows: new Set<string>(),
            affectedRows: null
        });
    };

    handleGroupToggle = ({ id: TId }: IGroupToggleEvent): void => {
        const newRows = updateRow(this.state.rows, TId, (row) => {
            return {
                ...row,
                open: !row.open
            };
        });

        const allGroupStatus = this.getGroupToggleState(this.getRowsArray(newRows));

        this.setState({
            rows: newRows,
            allGroupStatus
        }, this.props.onGroupToggle);
    };

    toggleAllGroups = (status: GroupStatus) => {
        if (status === GroupStatus.Unknown) {
            return;
        }
        const newRows = {
            ...this.state.rows
        };

        for (const rowId of this.state.rowsOrder) {
            const row = getRow(newRows, rowId);

            if (row.rows) {
                newRows[rowId.toString()] = {
                    ...row,
                    open: status === GroupStatus.Expanded
                };
            }
        }

        this.saveGroupRowsState(this.getRowsArray(newRows));

        this.setState({
            rows: newRows,
            allGroupStatus: status
        }, this.props.onGroupToggle);
    };

    getGroupToggleState = (rows: IRow[], { ignoreCount = false, preventSaving = false } = {}): GroupStatus => {
        const rowCount = this.getTableState().rowCount;
        const status = getGroupToggleState(rows, { ignoreCount, rowCount });

        if (!preventSaving) {
            this.saveGroupRowsState(rows);
        }

        return status;
    };

    handleRowSelect = (bindingContext: BindingContext, props: IRowProps, modifiers?: IModifierKeys): void => {
        if (this.props.onRowSelect && !bindingContext.toString().startsWith(LOADING_ROW_ID)) {
            // for grouped rows, pass parentId instead
            const rowId = props.groupId ?? bindingContext;
            this.props.onRowSelect(rowId as BindingContext, props, modifiers);
        }
    };

    handleRowContextMenuSelection = (bindingContext: BindingContext, props: IRowProps, modifiers?: IModifierKeys): void => {
        if (this.props.onRowContextMenuSelection && !bindingContext.toString().startsWith(LOADING_ROW_ID)) {
            // for grouped rows, pass parentId instead
            const rowId = props.groupId ?? bindingContext;
            this.props.onRowContextMenuSelection(rowId as BindingContext, props, modifiers);
        }
    };

    toggleAll = async (rowAction: RowAction, checked: boolean): Promise<void> => {
        if (checked && !this.areAllRowsLoaded()) {
            this.setState({ loadingActionCount: true }, this.props.onGroupToggle);
            await this.fetchData({ loadAll: true });
        }

        return new Promise((resolve) => {
            const activeRows = new Set<string>();

            for (const row of Object.values(this.state.rows)) {
                if (rowAction !== RowAction.Lock && row.isLocked && rowAction !== RowAction.Custom) {
                    continue;
                }

                if (checked) {
                    if (!this.isRowWithoutAction(row.id, rowAction)) {
                        let isRowDisabled = false;

                        if (rowAction === RowAction.Lock) {
                            isRowDisabled = this.isRowWithoutLockAction(row);
                        }

                        if (!isRowDisabled) {
                            activeRows.add(row.id.toString());
                            if (activeRows.size === 1) {
                                // some actions(isRowWithoutAction) are dependent on first selected row
                                this.setState({ activeRows });
                            }
                        }
                    } else if (!this.isRowWithoutAction(row.customData?.parent?.id, rowAction)) {
                        activeRows.add(row.customData.parent.id.toString());
                    }
                }
            }

            this.setState({
                // this.state.rowCount stores just highest level of rows, but we have to use it for smartTable, because
                // we load just first 100 rows, on the other hand for hierarchy we have to go through all rows and levels
                // but in hierarchy table we do load all rows at once, so it's working just fine
                activeRows,
                changedRows: this.getChangedRows(activeRows),
                loadingActionCount: false,
                toggleState: checked ? activeRows.size === 0 ? ToggleState.Disabled : ToggleState.AllChecked : ToggleState.AllUnchecked
            }, resolve);
        });
    };

    handleToggleChange = async (toggleState: ToggleState): Promise<void> => {
        if (this.props.rowAction?.onToggleChange) {
            this.props.rowAction.onToggleChange(toggleState);
        } else {
            await this.toggleAll(this.rowActionType, toggleState === ToggleState.AllChecked);
            this.props.onActiveRowActionCountChange?.(this.state.activeRows.size);
        }
    };

    isRowWithoutLockAction = (row: IRow): boolean => {
        const draftProperty = this.props.storage?.data.definition.draftDef?.draftProperty;

        return this.props.formStorage?.isDirty(row.id as BindingContext) || isDocumentWithDraft(row?.customData?.entity, draftProperty as string);
    };

    /** Returns all (even nested) rows as array.
     * Don't use if not needed, instead use state.rows which is a hashmap */
    getRowsArray = memoizeOne((rows: Record<string, IRow> = this.state.rows, rowsOrder: string[] = this.getTableState().rowsOrder): IRow[] => {
        let rowsArray = getRowsArrayFromRows(rows, rowsOrder);


        if (this.getTableState().loaded) {
            if (this.rowActionType === RowAction.Lock) {
                for (let i = 0; i < rowsArray.length; i++) {
                    const row = rowsArray[i];

                    if (!row || row.isLoading) {
                        continue;
                    }

                    const isRowDisabled = this.isRowWithoutLockAction(row);

                    if (isRowDisabled !== row.isDisabled) {
                        rowsArray[i] = {
                            ...rowsArray[i],
                            isDisabled: isRowDisabled
                        };
                    }
                }
            }

            if (this.props.rowsFactory) {
                rowsArray = this.props.rowsFactory(rowsArray);
            }
        }

        return rowsArray;
    }, (rows?: Record<string, IRow>, rowsOrder?: string[]) => {
        const arr = [this.state.rowsOrder];

        if (this.props.rowsFactory) {
            // never memoize if rowsFactory used, we need to call it every time
            arr.push(uuidv4());
        }

        return arr;
    });

    renderCustomBusyContent = (): React.ReactNode => {
        if (isNotDefined(this.state.actionProgressCurrent)) {
            return null;
        }

        return (
            <ProgressBar value={this.state.actionProgressCurrent}
                         parts={this.state.actionProgressMax}
                         customDescription={this.props.storage.t("Components:Table.RowsProcessed")}/>
        );
    };

    getColumns = memoizeOne((): IFieldInfo[] => {
        const state = this.getTableState();

        return [...state.columns, ...state.childColumns.filter(childColumn => !state.columns.find(column => column.id === childColumn.id))];
    }, () => {
        const state = this.getTableState();

        return [state.columns, state.childColumns];
    });

    render() {
        if (this.props.filter?.isInvalid) {
            return <NoData/>;
        }

        const state = this.getTableState();
        const rowAction = this.getRowAction();
        const columns = this.getColumns();

        return (
            <>
                {this.props.alert}
                {this.isBulkChangeDialogOpen() &&
                    <BulkChangeDialog onConfirm={this.handleBulkChangeDialogConfirm}
                                      onCancel={this.handleBulkChangeDialogCancel}
                                      tableStorage={this.props.storage}
                                      bindingContext={this.props.bindingContext}
                    />}
                <SmartTableBase {...this.props}
                                rows={this.getRowsArray()}
                                passRef={this.props.passRef}
                                rowCount={state.rowCount}
                                columns={columns}
                                minimumBatchSize={TableBatch.InfiniteLoaderMinBatchSize}
                                sort={this.getSort()}
                                disableVirtualization={this.props.disableVirtualization}
                                isForPrint={this.props.isForPrint}
                                onRowSelect={this.handleRowSelect as (row: TId, props: IRowProps, modifiers?: IModifierKeys) => void}
                                onRowContextMenuSelection={this.handleRowContextMenuSelection as (row: TId, props: IRowProps, modifiers?: IModifierKeys) => void}
                                onGroupToggle={this.handleGroupToggle}
                                onSortChange={this.handleSortChange}
                                onLoadMoreRows={this.handleLoadMoreRows}
                                rowAction={rowAction}
                                rowIcon={this.props.getRowIcon}
                                loaded={state.loaded}
                                customBusyContent={this.renderCustomBusyContent()}/>
            </>
        );
    }
}

export { SmartODataTableBase };