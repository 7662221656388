import { IGetValueArgs } from "@components/smart/FieldInfo";
import { IBankAccountEntity } from "@odata/GeneratedEntityTypes";
import { RecurringTaskStateCode } from "@odata/GeneratedEnums";
import { DefaultTheme } from "styled-components";

import BindingContext from "../../odata/BindingContext";
import { IFormStorageDefaultCustomData } from "../../views/formView/FormStorage";

export const RecurringTaskDraftId = "recurringTaskDraft";

export interface IRecurringTaskCustomData extends IFormStorageDefaultCustomData {
    DateNextExecution?: Date;
    copyDraftEntity?: boolean;
    bankAccounts?: IBankAccountEntity[];
}


export const getStatusColor = (StatusCode: RecurringTaskStateCode): keyof DefaultTheme => {
    let color: keyof DefaultTheme;

    switch (StatusCode) {
        case RecurringTaskStateCode.Active:
            color = "C_SEM_text_good";
            break;
        case RecurringTaskStateCode.Blocked:
            color = "C_SEM_text_bad";
            break;
        case RecurringTaskStateCode.Paused:
        default:
            color = "C_TEXT_primary";
            break;
    }
    return color;
};

export enum RecurringTaskFormViewAction {
    ChooseTask = "ChooseTask",
    EditTask = "EditTask"
}

export const hasDraft = (args: IGetValueArgs): boolean => {
    const draft = args.storage.data.entity.DocumentDraft;
    return !!draft?.Id || !!draft?.[BindingContext.NEW_ENTITY_ID_PROP];
};
